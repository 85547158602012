/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require("./bootstrap");

window.Vue = require("vue").default;

import router from "./router";
import store from "./store/store";
import i18n from "./i18n/index";

import VueNotification from "@mathieustan/vue-notification";
Vue.use(VueNotification, {
    theme: {
        colors: {
            success: "#34D399",
            info: "#818CF8",
            warning: "#FBBF24",
            error: "#F87171",
            offline: "#111827",
        },
    },
});

import VueCountryCode from "vue-country-code-select";
Vue.use(VueCountryCode);

import vSelect from "vue-select";
Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";

// icons
import icons from "./icons";

for (const key in icons) {
    Vue.component(key, icons[key]);
}

import VueSweetalert2 from "vue-sweetalert2";

const options = {
    confirmButtonColor: "#059669",
    cancelButtonColor: "#ef4444",
};

// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";

Vue.use(VueSweetalert2, options);

// Swiper Vue Styles
import "swiper/swiper-bundle.css";

const app = new Vue({
    el: "#app",
    router,
    store,
    i18n,
});
