import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
    mode: "history",
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes: [
        {
            path: "/",
            component: () => import("./layouts/Main.vue"),
        },
        {
            path: "",
            component: () => import("./layouts/Dashboard.vue"),
            beforeEnter: (to, from, next) => {
                if (!localStorage.getItem("token")) next("login-admin");
                else next();
            },
            children: [
                {
                    path: "/landing-options",
                    name: "landing-options",
                    component: () =>
                        import("./components/admin/landing-options/View.vue"),
                },
                {
                    path: "/mobile-customizer",
                    name: "app-customizer",
                    component: () =>
                        import("./components/admin/mobile/View.vue"),
                },
                {
                    path: "/appearance",
                    name: "appearance",
                    component: () =>
                        import("./components/admin/appearance/View.vue"),
                },
                {
                    path: "/categories",
                    name: "categories",
                    component: () =>
                        import("./components/admin/categories/View.vue"),
                },
                {
                    path: "/categories/:category_id/subcategories",
                    name: "subcategories",
                    component: () =>
                        import(
                            "./components/admin/categories/Subcategories.vue"
                        ),
                },
                {
                    path: "/categories/curate/:category_id?",
                    name: "categories-curate",
                    component: () =>
                        import("./components/admin/categories/Curate.vue"),
                },
                {
                    path: "/categories/update-image/:category_id",
                    name: "categories-curate-img",
                    component: () =>
                        import("./components/admin/categories/UpdateImage.vue"),
                },
                {
                    path: "/categories/:category_id/products",
                    name: "products",
                    component: () =>
                        import("./components/admin/products/View.vue"),
                },
                {
                    path: "/categories/:category_id/products/mass-upload",
                    name: "mass-upload",
                    component: () =>
                        import("./components/admin/products/MassUpload.vue"),
                },
                {
                    path: "/categories/new-product/:category_id?",
                    name: "new-product",
                    component: () =>
                        import("./components/admin/products/Curate.vue"),
                },
                {
                    path: "/categories/:category_id/products/:product_id/curate",
                    name: "products-curate",
                    component: () =>
                        import("./components/admin/products/Curate.vue"),
                },
                {
                    path: "/products-verify",
                    name: "verify-products",
                    component: () =>
                        import("./components/admin/products/List.vue"),
                },
                {
                    path: "/products/create-from-draft/:id/on/:cat",
                    name: "draft-products",
                    component: () =>
                        import("./components/admin/products/Draft.vue"),
                },
                {
                    path: "/brands/manage",
                    name: "brands",
                    component: () =>
                        import("./components/admin/brands/View.vue"),
                },
                {
                    path: "/variants/manage",
                    name: "variants",
                    component: () =>
                        import("./components/admin/categories/Variants.vue"),
                },
                {
                    path: "/orders",
                    name: "branches-orders",
                    component: () =>
                        import("./components/admin/orders/ViewBranches.vue"),
                },
                {
                    path: "/branches/:id/orders",
                    name: "orders",
                    component: () =>
                        import("./components/admin/orders/ViewOrders.vue"),
                },
                {
                    path: "/orders/curate/:order_id?",
                    name: "orders-curate",
                    component: () =>
                        import("./components/admin/orders/Curate.vue"),
                },
                {
                    path: "/branches",
                    name: "branches",
                    component: () =>
                        import("./components/admin/branches/View.vue"),
                },
                {
                    path: "/branches-mass-upload",
                    name: "branches-mass-upload",
                    component: () =>
                        import("./components/admin/branches/Mass.vue"),
                },
                {
                    path: "/branches/curate/:branch_id?",
                    name: "branches-curate",
                    component: () =>
                        import("./components/admin/branches/Curate.vue"),
                },
                {
                    path: "/branches/show/:branch_id",
                    name: "branches-show",
                    component: () =>
                        import("./components/admin/branches/Show.vue"),
                },
                {
                    path: "/offers",
                    name: "offers",
                    component: () =>
                        import("./components/admin/offers/ViewOffers.vue"),
                },
                {
                    path: "/offers/create",
                    name: "create-offer",
                    component: () =>
                        import("./components/admin/offers/CurateOffer.vue"),
                },
                {
                    path: "/offers/:id/edit",
                    name: "edit-offer",
                    component: () =>
                        import("./components/admin/offers/CurateOffer.vue"),
                },
                {
                    path: "/advertisers",
                    name: "advertisers",
                    component: () =>
                        import("./components/admin/offers/ViewAdvertisers.vue"),
                },
                {
                    path: "/staff",
                    name: "staff",
                    component: () =>
                        import("./components/admin/staff/View.vue"),
                },
                {
                    path: "/users",
                    name: "users",
                    component: () =>
                        import("./components/admin/users/View.vue"),
                },
                {
                    path: "/clients",
                    name: "clients",
                    component: () =>
                        import("./components/admin/clients/View.vue"),
                },
                {
                    path: "/upload-clients",
                    name: "upload-clients",
                    component: () =>
                        import("./components/admin/clients/Mass.vue"),
                },
                {
                    path: "/invited-clients",
                    name: "invited-clients",
                    component: () =>
                        import("./components/admin/clients/Invites.vue"),
                },
                {
                    path: "/settings",
                    name: "settings",
                    component: () =>
                        import("./components/admin/settings/General.vue"),
                },
            ],
        },
        {
            path: "",
            component: () => import("./layouts/Wizard.vue"),
            children: [
                {
                    path: "/setup-store",
                    name: "setup-store",
                    component: () => import("./components/setup/Welcome.vue"),
                },
                {
                    path: "/login-admin",
                    name: "admin-login",
                    component: () => import("./components/auth/loginAdmin.vue"),
                    beforeEnter: (to, from, next) => {
                        if (localStorage.getItem("token") !== null) {
                            next("orders");
                        } else {
                            next();
                        }
                    },
                },
                {
                    path: "/pos/:phone?",
                    name: "pos",
                    component: () => import("./components/admin/pos/View.vue"),
                    beforeEnter: (to, from, next) => {
                        if (!localStorage.getItem("token")) {
                            next({ name: "admin-login" });
                        } else {
                            next();
                        }
                    },
                },
            ],
        },
        {
            // 404 Not Found
            path: "*",
            component: () => import("./layouts/NotFound.vue"),
        },
    ],
});

export default router;
