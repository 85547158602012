import axios from "axios";
import auth from "./axios-auth";

const state = {
    token: null,
    user: null,
    data: null,
};

const getters = {
    token(state) {
        return state.token;
    },
    user(state) {
        return state.user;
    },
    isLoggedIn(state) {
        return state.token != null && state.user != null;
    },
    favourites(state) {
        return state.user.favourites;
    },
    user_info(state) {
        return state.data;
    },
};

const actions = {
    register({}, data) {
        return new Promise((resolve, reject) => {
            axios.post("/api/auth/register", data).then(
                (response) => {
                    resolve(response);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    },
    login({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.post("/api/auth/login", payload.data).then(
                (response) => {
                    commit("SET_DATA", payload.data);

                    dispatch("attempt", response.data.access_token);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    },
    attempt({ commit, dispatch, rootGetters }, token) {
        return new Promise((resolve, reject) => {
            axios
                .get("/api/auth/me", {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then(
                    (response) => {
                        commit("SET_TOKEN", token);
                        commit("SET_USER", response.data);

                        // store to localstorage
                        localStorage.setItem("token", token);
                        localStorage.setItem(
                            "user",
                            JSON.stringify(response.data)
                        );

                        auth.defaults.headers.common[
                            "Authorization"
                        ] = `Bearer ${token}`;

                        dispatch("addresses/getaddresses", response.data.id, {
                            root: true,
                        });

                        if (
                            [
                                "superadmin",
                                "admin",
                                "call_center_manager",
                                "call_center",
                                "operator",
                            ].includes(response.data.role)
                        ) {
                            window.location.replace("/orders");
                            // window.location.replace("/dashboard");
                        } else {
                            if (document.documentElement.clientWidth <= 736) {
                                const url =
                                    rootGetters["cart"].length > 0 ||
                                    rootGetters["buyNow"].length > 0
                                        ? "/mobile/shipping"
                                        : "/";

                                window.location.replace(url);
                            } else {
                                const url =
                                    rootGetters["cart"].length > 0 ||
                                    rootGetters["buyNow"].length > 0
                                        ? "/checkout"
                                        : "/";

                                window.location.replace(url);
                            }
                        }

                        resolve(response);
                    },
                    (error) => {
                        commit("SET_TOKEN", null);
                        commit("SET_USER", null);
                    }
                );
        });
    },
    refreshToken({ dispatch, commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.post("/api/auth/login", payload).then(
                (response) => {
                    commit("SET_DATA", payload);
                    dispatch("attemptRefresh", response.data.access_token);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    },
    attemptRefresh({ commit, dispatch }, token) {
        return new Promise((resolve, reject) => {
            axios
                .get("/api/auth/me", {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then(
                    (response) => {
                        commit("SET_TOKEN", token);
                        commit("SET_USER", response.data);

                        // store to localstorage
                        localStorage.setItem("token", token);
                        localStorage.setItem(
                            "user",
                            JSON.stringify(response.data)
                        );

                        auth.defaults.headers.common[
                            "Authorization"
                        ] = `Bearer ${token}`;

                        dispatch("addresses/getaddresses", response.data.id, {
                            root: true,
                        });

                        resolve(response);
                    },
                    (error) => {
                        commit("SET_TOKEN", null);
                        commit("SET_USER", null);
                    }
                );
        });
    },
    logout({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            auth.post("/api/auth/logout").then(
                (response) => {
                    commit("SET_TOKEN", null);
                    commit("SET_USER", null);
                    commit("SET_DATA", null);

                    dispatch(
                        "addresses/clearAddresses",
                        {},
                        {
                            root: true,
                        }
                    );

                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    window.location.replace(payload);
                    resolve(response);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    },
    async updateFavourites({ commit }, payload) {
        await commit("UPDATE_USER_FAVOURITES", payload);
    },
    resetPassword({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            auth.post("/api/auth/reset-password/" + payload.id, payload).then(
                (response) => {
                    if (response.data.statusCode == 200) {
                        dispatch("login", {
                            data: {
                                phone: payload.phone,
                                password: payload.password,
                            },
                            checkout: false,
                        });
                        resolve(response);
                    }
                },
                (error) => {
                    reject(error);
                }
            );
        });
    },
    createUsers({}, payload) {
        return new Promise((resolve, reject) => {
            auth.post("/api/auth/create-users", payload).then(
                (response) => {
                    if (response.data.statusCode == 200) {
                        resolve(response);
                    }
                },
                (error) => {
                    reject(error);
                }
            );
        });
    },
    verifyClient({}, payload) {
        return new Promise((resolve, reject) => {
            auth.post("/api/auth/verify/" + payload).then(
                (response) => {
                    // console.log(response.data);
                    if (response.data.statusCode == 200) {
                        resolve(response);
                    }
                },
                (error) => {
                    reject(error);
                }
            );
        });
    },
    refreshUserData({ state, commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get("/api/auth/me", {
                    headers: {
                        Authorization: `Bearer ${state.token}`,
                    },
                })
                .then(
                    (response) => {
                        commit("SET_USER", response.data);

                        // store to localstorage
                        localStorage.setItem(
                            "user",
                            JSON.stringify(response.data)
                        );

                        resolve(response);
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        });
    },
};

const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token;
    },
    SET_USER: (state, user) => {
        state.user = user;
    },
    UPDATE_USER_FAVOURITES: (state, favourites) => {
        state.user.favourites = favourites;
    },
    SET_DATA: (state, data) => {
        state.data = data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
