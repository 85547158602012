export default {
    "heart-icon": () =>
        import("vue-material-design-icons/CardsHeartOutline.vue"),
    "search-icon": () => import("vue-material-design-icons/Magnify.vue"),
    "favorite-icon": () => import("vue-material-design-icons/CardsHeart.vue"),
    "increment-icon": () => import("vue-material-design-icons/PlusCircle.vue"),
    "decrement-icon": () => import("vue-material-design-icons/MinusCircle.vue"),
    "eye-icon": () => import("vue-material-design-icons/Eye.vue"),
    "menu-icon": () => import("vue-material-design-icons/Menu.vue"),
    "cart-icon": () => import("vue-material-design-icons/Cart.vue"),
    "trash-icon": () => import("vue-material-design-icons/TrashCan.vue"),
    "image-file-icon": () =>
        import("vue-material-design-icons/FileImagePlus.vue"),
    "cog-icon": () => import("vue-material-design-icons/Cog.vue"),
    "expand-icon": () =>
        import("vue-material-design-icons/ChevronDownCircleOutline.vue"),
    "collapse-icon": () =>
        import("vue-material-design-icons/ChevronUpCircle.vue"),
    "edit-icon": () => import("vue-material-design-icons/Pencil.vue"),
    "plus-icon": () => import("vue-material-design-icons/Plus.vue"),
    "save-icon": () => import("vue-material-design-icons/ContentSave.vue"),
    "upload-icon": () => import("vue-material-design-icons/FileUpload.vue"),
    "uploading-icon": () => import("vue-material-design-icons/Upload.vue"),
    "brush-icon": () => import("vue-material-design-icons/Brush.vue"),
    "check-icon": () => import("vue-material-design-icons/CheckBold.vue"),
    "close-icon": () => import("vue-material-design-icons/Close.vue"),
    "headset-icon": () => import("vue-material-design-icons/Headset.vue"),
    "next-icon": () => import("vue-material-design-icons/ChevronRight.vue"),
    "prev-icon": () => import("vue-material-design-icons/ChevronLeft.vue"),
    "back-icon": () => import("vue-material-design-icons/ArrowLeft.vue"),
    "profile-icon": () => import("vue-material-design-icons/AccountCircle.vue"),
    "caret-down-icon": () => import("vue-material-design-icons/MenuDown.vue"),
    "chevron-up-icon": () => import("vue-material-design-icons/ChevronUp.vue"),
    "promo-icon": () => import("vue-material-design-icons/TicketPercent.vue"),
    "chevron-down-icon": () =>
        import("vue-material-design-icons/ChevronDown.vue"),
};
