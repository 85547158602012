import axios from "./axios-auth";

const state = {
    offerTypes: [],
};

const getters = {
    offerTypes(state) {
        return state.offerTypes;
    },
};

const actions = {
    createOfferType({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post(`/api/offer-types`, payload)
                .then((response) => {
                    if (response.data.statusCode === 201) {
                        dispatch("fetchOfferTypes");
                    }

                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    fetchOfferTypes({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/api/offer-types`)
                .then((response) => {
                    if (response.data.statusCode === 200) {
                        commit("SET_OFFER_TYPES", response.data.offer_types);
                    }

                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    editOfferType({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .put(`/api/offer-types/${payload.id}`, payload)
                .then((response) => {
                    if (response.data.statusCode === 202) {
                        dispatch("fetchOfferTypes");
                    }

                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    removeOfferType({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .delete(`/api/offer-types/${payload}`)
                .then((response) => {
                    if (response.data.statusCode === 202) {
                        dispatch("fetchOfferTypes");
                    }

                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};

const mutations = {
    SET_OFFER_TYPES: (state, payload) => {
        state.offerTypes = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
