import axios from "./axios-auth";

const state = {
    offers: [],
};

const getters = {
    offers(state) {
        return state.offers;
    },
};

const actions = {
    fetchOffers({ commit }, query = "") {
        return new Promise((resolve, reject) => {
            let url = `/api/offers`;

            if (query !== "") {
                url += `?${query}`;
            }

            axios
                .get(url)
                .then((response) => {
                    if (response.data.statusCode === 200) {
                        commit("SET_OFFERS", response.data.offers);
                    }

                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    createOffer({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post(`/api/offers`, payload)
                .then((response) => {
                    if (response.data.statusCode === 201) {
                        dispatch("fetchOffers");
                    }

                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    updateOffer({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .put(`/api/offers/${payload.id}`, payload)
                .then((response) => {
                    if (response.data.statusCode === 202) {
                        dispatch("fetchOffers");
                    }

                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    removeOffer({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .delete(`/api/offers/${payload}`)
                .then((response) => {
                    if (response.data.statusCode === 202) {
                        dispatch("fetchOffers");
                    }

                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};

const mutations = {
    SET_OFFERS: (state, payload) => {
        state.offers = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
