import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import axiauth from "./modules/axios-auth";

Vue.use(Vuex);

// store.js
import i18n from "../i18n/index.js";

import createPersistedState from "vuex-persistedstate";

// modules
import auth from "./modules/auth";
import brands from "./modules/brands";
import users from "./modules/users";
import orders from "./modules/orders";
import products from "./modules/products";
import branches from "./modules/branches";
import categories from "./modules/categories";
import zones from "./modules/zones";
import addresses from "./modules/addresses";
import promocodes from "./modules/promocodes";
import deals from "./modules/deals";
import ads from "./modules/ads";
import media from "./modules/media";
import homeBuilder from "./modules/home-builder";
import subcategories from "./modules/subcategories";
import advertisers from "./modules/advertisers";
import offerTypes from "./modules/offer-types";
import offers from "./modules/offers";

// functions
const objectsEqual = (o1, o2) =>
    typeof o1 === "object" && Object.keys(o1).length > 0
        ? Object.keys(o1).length === Object.keys(o2).length &&
          Object.keys(o1).every((p) => objectsEqual(o1[p], o2[p]))
        : o1 === o2;

const arraysEqual = (a1, a2) =>
    a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));

const checkOptions = (cart, extras, ingredients) => {
    let extras_match = checkExtras(cart, extras);
    let ingredients_match = checkIngredients(cart, ingredients);

    return {
        extras: extras_match,
        modifiers: ingredients_match,
    };
};

const checkExtras = (cart, extras) => {
    for (const item of cart) {
        let exids = item.extras.map((ex) => ex.id);

        if (JSON.stringify(extras) == JSON.stringify(exids)) {
            return {
                match: true,
                item: cart.findIndex((el) => el.product_id == item.product_id),
            };
        } else {
            return { match: false };
        }
    }
};

const checkIngredients = (cart, ingredients) => {
    for (const item of cart) {
        let ingids = item.ingredients.map((ex) => ex.id);

        if (JSON.stringify(ingredients) == JSON.stringify(ingids)) {
            return {
                match: true,
                item: cart.findIndex((el) => el.product_id == item.product_id),
            };
        } else {
            return { match: false };
        }
    }
};

const createID = (product, size, extras, modifiers) => {
    let unique = JSON.stringify(product) + "-" + JSON.stringify(size);
    unique += extras.length > 0 ? "-" + extras.join(".") : "-0";
    unique += modifiers.length > 0 ? "-" + modifiers.join(".") : "-0";

    return unique;
};

const swapElements = (array, index1, index2) => {
    array[index1] = array.splice(index2, 1, array[index1])[0];
};

export default new Vuex.Store({
    state: {
        language: "ar",
        homePageLayout: [],
        widgetsMenuOpen: true,
        thereIsAModalOpen: false,
        landingOptions: {},
        landingMobileMenu: false,
        isFoodMenuOpen: false,
        currentCategory: 0,
        currentView: "list",
        dashboardSidebar: false,
        settings: {},
        screenSize: null,
        lang: "ar",
        cart: [],
        buyNow: [],
        totalQty: 0,
        totalPrice: 0,
        step: 1,
        deliveryPrice: 20,
        deliveryInfo: {
            name: "",
            phone: "",
            phone2: "",
            address: {
                id: null,
                street: "",
                building: "",
                floor: "",
                flat: "",
                zone_id: null,
            },
        },
        mobileLayout: [],
        mobileHeader: [],
        shipping: null,
        appearance: {
            columns: "4",
            gap: "16",
            containerPadding: { topBottom: 16, leftRight: 16 },
            card: {
                padding: { topBottom: 16, leftRight: 16 },
                dropShadow: {
                    offsetX: 0,
                    offsetY: 0,
                    blurRadius: 0,
                    color: "#e8e8e8",
                },
                borderRadius: "10",
            },
            image: {
                height: "300",
                dropShadow: {
                    offsetX: 0,
                    offsetY: 0,
                    blurRadius: "0",
                    color: "#e6e6e6",
                },
            },
        },
    },
    getters: {
        language(state) {
            return state.language;
        },
        shipping(state) {
            return state.shipping;
        },
        homeLayout(state) {
            return state.homePageLayout;
        },
        appLayout(state) {
            return state.mobileLayout;
        },
        mobileHeader(state) {
            return state.mobileHeader;
        },
        isWidgetsVisible(state) {
            return state.widgetsMenuOpen;
        },
        thereIsAModalOpen(state) {
            return state.thereIsAModalOpen;
        },
        landingScreen(state) {
            return state.landingMobileMenu;
        },
        landing(state) {
            return state.landingOptions;
        },
        desktopFoodMenu(state) {
            return state.isFoodMenuOpen;
        },
        appearance(state) {
            return state.appearance;
        },
        view(state) {
            return state.currentView;
        },
        sidebarState(state) {
            return state.dashboardSidebar;
        },
        current(state) {
            return state.currentCategory;
        },
        screen(state) {
            return state.screenSize;
        },
        settings(state) {
            return state.settings;
        },
        logo(state) {
            return state.settings.restaurant_logo;
        },
        cart(state) {
            return state.cart;
        },
        buyNow(state) {
            return state.buyNow;
        },
        tq(state) {
            return state.totalQty;
        },
        tp(state) {
            return state.totalPrice;
        },
        dp(state) {
            return state.deliveryPrice;
        },
        step(state) {
            return state.step;
        },
        delivery_details(state) {
            return state.deliveryInfo;
        },
    },
    actions: {
        setLanguage({ commit }, payload) {
            commit("SET_LANGUAGE", payload);
        },
        fetchMobileHeader({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get("/api/mobile-header").then((response) => {
                    commit("SET_MOBILE_HEADER_LAYOUT", response.data);
                    resolve(response);
                });
            });
        },
        saveMobileHeader({ dispatch }, paylaod) {
            return new Promise((resolve, reject) => {
                axios.post("/api/mobile-header", paylaod).then((response) => {
                    dispatch("fetchMobileHeader");
                    resolve(response);
                });
            });
        },
        fetchMobileLayout({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get("/api/mobile-layout").then((response) => {
                    commit("SET_MOBILE_LAYOUT", response.data);
                    resolve(response);
                });
            });
        },
        saveMobileLayout({ dispatch }, paylaod) {
            return new Promise((resolve, reject) => {
                axios.post("/api/mobile-layout", paylaod).then((response) => {
                    dispatch("fetchMobileLayout");
                    resolve(response);
                });
            });
        },
        async addMobileSection({ commit }, payload) {
            await commit("ADD_MOBILE_SECTION", payload);
        },
        async removeMobileSection({ commit }, payload) {
            await commit("REMOVE_MOBILE_SECTION", payload);
        },
        swapMobileSectionUp({ commit }, payload) {
            commit("SWAP_POS_UP", payload);
        },
        swapMobileSectionDown({ commit }, payload) {
            commit("SWAP_POS_DOWN", payload);
        },
        async toggleWidgetsMenu({ commit }) {
            await commit("TOGGLE_WIDGETS_MENU");
        },
        async setAModalAsOpen({ commit }, payload) {
            commit("SET_MODAL_OPEN", payload);
        },
        toggleLandingMobileMenu({ commit }, payload) {
            commit("LANDING_MENU_TOGGLE", payload);
        },
        getLandingOptions({ commit }) {
            axios.get("/api/landings").then((res) => {
                commit("SET_LANDING_OPTIONS", res.data);
            });
        },
        toggleFoodMenu({ commit }) {
            commit("TOGGLE_FOOD_MENU");
        },
        setViewMode({ commit }, paylaod) {
            commit("SET_VIEW_MODE", paylaod);
        },
        setScreenWidth({ commit }, payload) {
            commit("SET_SCREEN_WIDTH", payload);
        },
        toggleSidebar({ commit }) {
            commit("TOGGLE_SIDEBAR");
        },
        setCurrentCategory({ commit }, payload) {
            commit("SET_CURRENT_CATEGORY", payload);
        },
        async addItemToCart({ commit }, payload) {
            await commit("ADD_PRODUCT_TO_CART", payload);
        },
        emptyCart({ commit }) {
            commit("EMPTY_CART");
        },
        resetOrder({ commit }) {
            commit("RESET_ORDER");
        },
        async setDeliveryDetails({ commit }, payload) {
            await commit("SET_DELIVERY_INFO", payload);
        },
        async setDeliveryAddress({ commit }, payload) {
            await commit("SET_DELIVERY_INFO", payload);
        },
        async incrementCart({ commit }, payload) {
            await commit("INCREMENT_CART", payload);
        },
        async decrementCart({ commit }, payload) {
            await commit("DECREMENT_CART", payload);
        },
        async removeItems({ commit }) {
            await commit("EMPTY_CART_ITEMS");
        },
        async unshiftExtra({ commit }, payload) {
            await commit("REMOVE_EXTRA", payload);
        },
        async switchLanguage({ commit }) {
            await commit("SWITCH_LANGUAGE");
        },
        setSettings({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get("/api/settings").then(
                    (response) => {
                        commit("SET_SETTINGS", response.data);
                        resolve(response);
                    },
                    (error) => {
                        reject(error);
                    }
                );
            });
        },
        updateStoreSettings({ dispatch }, payload) {
            return new Promise((resolve, reject) => {
                axios.post(`/api/settings/update`, payload).then(
                    (response) => {
                        dispatch("setSettings");
                        resolve(response);
                    },
                    (error) => {
                        reject(error);
                    }
                );
            });
        },
        updateStoreAvatar({ dispatch }, payload) {
            return new Promise((resolve, reject) => {
                axios.post(`/api/settings/update-avatar`, payload.data).then(
                    (response) => {
                        dispatch("setSettings");
                        resolve(response);
                    },
                    (error) => {
                        reject(error);
                    }
                );
            });
        },
        getAppearanceSettings({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get("/api/appearances").then(
                    (response) => {
                        commit("SET_APPEARANCE", response.data);
                        resolve(response);
                    },
                    (error) => {
                        reject(error);
                    }
                );
            });
        },
        updateAppearanceSettings({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios.post("/api/appearances/1", payload).then(
                    (response) => {
                        // console.log(response.data);
                        commit("SET_APPEARANCE", response.data.appearance);
                        resolve(response);
                    },
                    (error) => {
                        reject(error);
                    }
                );
            });
        },
        outOfStockAlert({ commit }, payload) {
            commit("OUT_OF_STOCK", payload);
        },
        getHomeLayout({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get("/api/home").then(
                    (response) => {
                        commit("SET_HOME_LAYOUT", JSON.parse(response.data));
                        resolve(response);
                    },
                    (error) => {
                        reject(error);
                    }
                );
            });
        },
        saveHomeLayout({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios.post("/api/appearances", payload).then(
                    (response) => {
                        // JSON.parse(payload.layout)
                        commit("SET_HOME_LAYOUT", JSON.parse(payload.layout));
                        resolve(response);
                    },
                    (error) => {
                        reject(error);
                    }
                );
            });
        },
        removeFromHome({ commit }, payload) {
            commit("REMOVE_FROM_HOME", payload);
        },
        async setCheckoutInfo({ commit }, payload) {
            await commit("SET_CHECKOUT_INFO", payload);
        },
        async addToBuyNowCart({ commit }, paylaod) {
            await commit("BUY_NOW_CART", paylaod);
        },
        async removeFromCart({ commit }, paylaod) {
            await commit("REMOVE_FROM_CART", paylaod);
        },
    },
    mutations: {
        SET_LANGUAGE: (state, payload) => {
            state.language = payload;
        },
        SET_MOBILE_HEADER_LAYOUT: (state, payload) => {
            state.mobileHeader = payload;
        },
        SET_MOBILE_LAYOUT: (state, payload) => {
            state.mobileLayout = payload;
        },
        ADD_MOBILE_SECTION: (state, paylaod) => {
            state.mobileLayout.push(paylaod);
        },
        REMOVE_MOBILE_SECTION: (state, index) => {
            state.mobileLayout.splice(index, 1);
        },
        SWAP_POS_UP: (state, index) => {
            const arr = state.mobileLayout;

            if (arr.length > 1 && index - 1 < arr.length) {
                if (index <= 0) {
                    const item = arr.shift();

                    arr.push(item);
                } else if (index == arr.length - 1) {
                    const item = arr.pop();

                    arr.unshift(item);
                } else {
                    arr.splice(index - 1, 0, arr.splice(index, 1)[0]);
                }
            }
        },
        SWAP_POS_DOWN: (state, index) => {
            const arr = state.mobileLayout;

            if (arr.length > 1 && index < arr.length) {
                if (index == arr.length - 1) {
                    const item = arr.pop();

                    arr.unshift(item);
                } else {
                    arr.splice(index + 1, 0, arr.splice(index, 1)[0]);
                }
            }
        },
        TOGGLE_WIDGETS_MENU: (state) => {
            state.widgetsMenuOpen = !state.widgetsMenuOpen;
        },
        SET_HOME_LAYOUT: (state, paylaod) => {
            state.homePageLayout = paylaod;
            // console.log(state.homePageLayout);
        },
        REMOVE_FROM_HOME: (state, payload) => {
            state.homePageLayout.splice(payload, 1);

            // change indexes
            state.homePageLayout.forEach((widget, index) => {
                widget.properties.index = index;
            });
        },
        OUT_OF_STOCK: (state, index) => {
            state.cart[index].isAvailable = false;

            state.cart.splice(index, 1, state.cart[index]);
        },
        SET_MODAL_OPEN: (state, payload) => {
            state.thereIsAModalOpen = payload;
        },
        LANDING_MENU_TOGGLE: (state, payload) => {
            state.landingMobileMenu = payload;
        },
        SET_LANDING_OPTIONS: (state, options) => {
            state.landingOptions = options;
        },
        SET_APPEARANCE: (state, appearance) => {
            state.appearance = appearance;
        },
        SET_VIEW_MODE: (state, payload) => {
            state.currentView = payload;
        },
        SET_SETTINGS: (state, settings) => {
            state.settings = settings;
        },
        SWITCH_LANGUAGE: (state) => {
            if (state.lang == "en") {
                state.lang = "ar";
                i18n.locale = state.lang;
                document.documentElement.dir = "rtl";
            } else {
                state.lang = "en";
                i18n.locale = state.lang;
                document.documentElement.dir = "ltr";
            }
        },
        EMPTY_CART: (state) => {
            state.cart = [];
            state.totalQty = 0;
            state.totalPrice = 0;
        },
        EMPTY_BUYNOW: (state) => {
            state.buyNow = [];
        },
        RESET_ORDER: (state) => {
            localStorage.setItem("step", 1);
            state.deliveryInfo = {
                name: "",
                phone: "",
                branch_id: 0,
                delivery_address: "",
            };
        },
        BUY_NOW_CART: (state, item) => {
            // new item
            let new_cart_item = item;
            new_cart_item.product_id = item.product_id;

            state.buyNow[0] = new_cart_item;
        },
        INCREMENT_BUYNOW_CART: (state) => {
            state.buyNow[0].qty++;
        },
        DECREMENT_BUYNOW_CART: (state) => {
            if (state.buyNow[0].qty > 1) {
                state.buyNow[0].qty--;
            } else {
                // remove item from buyNow
                state.buyNow.splice(0, 1);
            }
        },
        ADD_PRODUCT_TO_CART: (state, item) => {
            let index = state.cart.findIndex(
                (el) =>
                    el.product_id == item.product_id &&
                    el.options_ids == item.options_ids
            );

            if (index != -1) {
                // increase qty
                state.cart[index].qty += item.qty;

                // increase cart total
                state.totalPrice += item.price;

                // increase total quantity
                state.totalQty += item.qty;
            } else {
                // new item
                let new_cart_item = item;
                new_cart_item.product_id = item.product_id;

                state.cart.push(new_cart_item);
                state.totalQty += item.qty;
                state.totalPrice += item.price * item.qty;
            }
        },
        INCREMENT_CART: (state, index) => {
            state.cart[index].qty++;
            state.totalQty++;
            state.totalPrice += state.cart[index].price;
        },
        DECREMENT_CART: (state, index) => {
            if (state.cart[index].qty > 1) {
                state.cart[index].qty--;
                state.totalQty--;
                state.totalPrice -= state.cart[index].price;
            } else {
                state.totalQty -= state.cart[index].qty;
                state.totalPrice -= state.cart[index].price;

                // remove item from cart
                state.cart.splice(index, 1);
            }
        },
        EMPTY_CART_ITEMS: (state) => {
            state.cart = [];
            state.totalQty = 0;
            state.totalPrice = 0;
        },
        SET_DELIVERY_INFO: (state, info) => {
            state.deliveryInfo = info;
        },
        SET_DELIVERY_ADDRESS: (state, address) => {
            state.deliveryInfo.delivery_address = address;
        },
        ADD_NOTE_TO_ITEM: (state, payload) => {
            let item = state.cart.find((c) => c.id == payload.id);
            item.notes = payload.note;
        },
        SET_SCREEN_WIDTH: (state, width) => {
            state.screenSize = width;
        },
        SET_CURRENT_CATEGORY: (state, payload) => {
            state.currentCategory = payload;
        },
        TOGGLE_SIDEBAR: (state) => {
            state.dashboardSidebar = !state.dashboardSidebar;
        },
        TOGGLE_FOOD_MENU: (state) => {
            state.isFoodMenuOpen = !state.isFoodMenuOpen;
        },
        SET_CHECKOUT_INFO: (state, paylaod) => {
            state.shipping = paylaod;
        },
        REMOVE_FROM_CART: (state, index) => {
            state.totalQty -= state.cart[index].qty;
            state.totalPrice -= state.cart[index].price * state.cart[index].qty;
            state.cart.splice(index, 1);
        },
    },
    modules: {
        ads,
        auth,
        brands,
        users,
        orders,
        products,
        branches,
        categories,
        zones,
        addresses,
        promocodes,
        deals,
        media,
        homeBuilder,
        subcategories,
        advertisers,
        offerTypes,
        offers,
    },
    plugins: [createPersistedState()],
});
