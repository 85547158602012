import axios from "./axios-auth";

const state = {
    categories: [],
};

const getters = {
    all(state) {
        return state.categories;
    },
};

const actions = {
    getCategories({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            axios.get("/api/categories?is_admin=true").then((response) => {
                commit("SET_CATEGORIES", response.data.categories);
                resolve(response);
            });
        });
    },
    getCategory({}, payload) {
        return new Promise((resolve, reject) => {
            axios.get("/api/categories/" + payload).then((response) => {
                resolve(response);
            });
        });
    },
    searchProducts({}, payload) {
        return new Promise((resolve, reject) => {
            axios.get(payload).then((response) => {
                resolve(response);
            });
        });
    },
    searchCategoreis({}, payload) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `/api/categories/${payload.category}/search/${payload.query}`
                )
                .then((response) => {
                    resolve(response);
                });
        });
    },
    createCategory({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.post("/api/categories", payload).then((response) => {
                // console.log(response.data);
                // commit('ADD_CATEGORY', response.data.category)
                resolve(response);
            });
        });
    },
    setPromoCategoryProducts({}, { id, products }) {
        return new Promise((resolve, reject) => {
            axios
                .post(`/api/categories/${id}/promo`, products)
                .then((response) => {
                    resolve(response);
                });
        });
    },
    updateCategory({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/api/categories/" + payload.id, payload.data)
                .then((response) => {
                    resolve(response);
                });
        });
    },
    moveProduct({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .put(
                    `/api/categories/${payload.product}/move-to/${payload.category}`
                )
                .then((response) => {
                    if (response.data.statusCode == 200) {
                        window.location.reload();
                    }

                    resolve(response);
                });
        });
    },
    toggleVisibility({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post(`/api/categories/${payload.id}/visibility`, payload)
                .then((response) => {
                    resolve(response);
                });
        });
    },
    sideCategoryToggle({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post(`/api/categories/toggle/${payload}/priority`)
                .then((response) => {
                    resolve(response);
                });
        });
    },
    removeCategory({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.delete("/api/categories/" + payload).then((response) => {
                commit("REFRESH_CATEGORIES");
                resolve(response);
            });
        });
    },
};

const mutations = {
    SET_CATEGORIES: (state, categories) => (state.categories = categories),
    ADD_CATEGORY: (state, category) => state.categories.push(category),
    REFRESH_CATEGORIES: (state, category_id) => {
        const index = state.categories.findIndex(
            (category) => category.id == category_id
        );
        state.categories.splice(index, 1);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
