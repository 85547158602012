import axios from "axios";
import axiauth from "./axios-auth";

const state = {
    homePageLayout: [],
    saleProducts: [],
};

const getters = {
    homeLayout(state) {
        return state.homePageLayout;
    },
    saleProducts(state) {
        return state.saleProducts;
    },
};

const actions = {
    getHomeLayout({ commit }) {
        return new Promise((resolve, reject) => {
            axios.get("/api/home").then(
                (response) => {
                    commit("SET_HOME_LAYOUT", response.data);
                    resolve(response);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    },
    getSaleProducts({ commit }) {
        return new Promise((resolve, reject) => {
            axios.get("/api/offers/sale-products").then(
                (response) => {
                    commit("SET_SALE_PRODUCTS", response.data);
                    resolve(response);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    },
    saveHomeLayout({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.post("/api/appearances", payload).then(
                (response) => {
                    commit("SET_HOME_LAYOUT", JSON.parse(payload.layout));
                    resolve(response);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    },
    removeFromHome({ commit }, payload) {
        commit("REMOVE_FROM_HOME", payload);
    },
    swapUp({ commit }, payload) {
        commit("SWAP_POS_UP", payload);
    },
    swapDown({ commit }, payload) {
        commit("SWAP_POS_DOWN", payload);
    },
};

const mutations = {
    SET_SALE_PRODUCTS: (state, paylaod) => {
        state.saleProducts = paylaod;
    },
    SET_HOME_LAYOUT: (state, paylaod) => {
        state.homePageLayout = paylaod;
    },
    REMOVE_FROM_HOME: (state, payload) => {
        state.homePageLayout.splice(payload, 1);

        // change indexes
        state.homePageLayout.forEach((widget, index) => {
            widget.properties.index = index;
        });
    },
    SWAP_POS_UP: (state, payload) => {
        if (state.homePageLayout.length > 1 && payload - 1 >= 0) {
            let from = state.homePageLayout.splice(payload, 1);

            // change indexes
            state.homePageLayout.forEach((widget, index) => {
                widget.properties.index = index;
            });

            state.homePageLayout.splice(payload - 1, 0, from[0]);

            for (let index = 0; index < state.homePageLayout.length; index++) {
                const element = state.homePageLayout[index];

                element.properties.index = index;
            }
        }
    },
    SWAP_POS_DOWN: (state, payload) => {
        if (state.homePageLayout.length > 1) {
            let from = state.homePageLayout.splice(payload, 1);

            // change indexes
            state.homePageLayout.forEach((widget, index) => {
                widget.properties.index = index;
            });

            state.homePageLayout.splice(payload + 1, 0, from[0]);

            for (let index = 0; index < state.homePageLayout.length; index++) {
                const element = state.homePageLayout[index];

                element.properties.index = index;
            }
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
