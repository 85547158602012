import axios from "./axios-auth";
// import noauth from "axios";

const state = {
    media: [],
};

const getters = {
    all(state) {
        return state.media;
    },
};

const actions = {
    getMedia({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.get("/api/media").then((response) => {
                commit("SET_MEDIA", response.data);
                resolve(response);
            });
        });
    },
    createMedia({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.post("/api/media", payload).then((response) => {
                dispatch("getMedia");
                resolve(response);
            });
        });
    },
    destroyMedia({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.delete(`/api/media/${payload}`).then((response) => {
                console.log(response.data);
                if (response.data.statusCode == 200) {
                    dispatch("getMedia");
                }
                resolve(response);
            });
        });
    },
};

const mutations = {
    SET_MEDIA: (state, payload) => {
        state.media = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
