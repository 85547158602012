import axios from "./axios-auth";

const state = {
    advertisers: [],
};

const getters = {
    advertisers(state) {
        return state.advertisers;
    },
};

const actions = {
    createAdvertiser({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post(`/api/advertisers`, payload)
                .then((response) => {
                    if (response.data.statusCode === 201) {
                        dispatch("fetchAdvertisers");
                    }

                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    fetchAdvertisers({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/api/advertisers`)
                .then((response) => {
                    if (response.data.statusCode === 200) {
                        commit("SET_ADVERTISERS", response.data.advertisers);
                    }

                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    editAdvertiser({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .put(`/api/advertisers/${payload.id}`, payload)
                .then((response) => {
                    if (response.data.statusCode === 202) {
                        dispatch("fetchAdvertisers");
                    }

                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    removeAdvertiser({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .delete(`/api/advertisers/${payload}`)
                .then((response) => {
                    if (response.data.statusCode === 202) {
                        dispatch("fetchAdvertisers");
                    }

                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};

const mutations = {
    SET_ADVERTISERS: (state, payload) => {
        state.advertisers = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
