import axios from "./axios-auth";

const state = {
    brands: [],
};

const getters = {
    all(state) {
        return state.brands;
    },
};

const actions = {
    fetchBrands({ commit }) {
        return new Promise((resolve, reject) => {
            axios.get("/api/brands").then((response) => {
                commit("SET_BRANDS", response.data.brands);

                resolve(response);
            });
        });
    },
    fetchBrand({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.get(`/api/brands/${payload}`).then((response) => {
                resolve(response);
            });
        });
    },
    createBrand({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.post("/api/brands", payload).then((response) => {
                if (response.data.statusCode == 200) {
                    dispatch("fetchBrands");
                }

                resolve(response);
            });
        });
    },
    updateImage({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.post("/api/brands/image", payload).then((response) => {
                if (response.data.statusCode == 200) {
                    dispatch("fetchBrands");
                }

                resolve(response);
            });
        });
    },
    brandProducts({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/api/brands/" + payload.id + "/products", payload)
                .then((response) => {
                    if (response.data.statusCode == 200) {
                        dispatch("fetchBrands");
                    }

                    resolve(response);
                });
        });
    },
    updateBrand({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.put("/api/brands/" + payload.id, payload).then((response) => {
                if (response.data.statusCode == 200) {
                    dispatch("fetchBrands");
                }

                resolve(response);
            });
        });
    },
    removeBrand({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.delete("/api/brands/" + payload).then((response) => {
                if (response.data.statusCode == 200) {
                    dispatch("fetchBrands");
                }

                resolve(response);
            });
        });
    },
};

const mutations = {
    SET_BRANDS: (state, payload) => {
        state.brands = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
