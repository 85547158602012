import axios from "axios";

const state = {
    subcategories: [],
};

const getters = {
    subcategories(state) {
        return state.subcategories;
    },
};

const actions = {
    createSubcategory({}, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/api/subcategories", payload)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => reject(error));
        });
    },
    fetchSubcategories({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/api/subcategories/${payload}`)
                .then((response) => {
                    if (response.data.statusCode == 200) {
                        commit(
                            "SET_SUBCATEGROIES",
                            response.data.subcategories
                        );
                    }

                    resolve(response);
                })
                .catch((err) => reject(error));
        });
    },
    fetchSubcategoryProducts({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/api/subcategories/${payload}/products`)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => reject(error));
        });
    },
    updateSubcategory({}, payload) {
        return new Promise((resolve, reject) => {
            axios
                .put(`/api/subcategories/${payload.id}`, payload)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => reject(error));
        });
    },
    removeSubcategory({}, payload) {
        return new Promise((resolve, reject) => {
            axios
                .delete(`/api/subcategories/${payload}`)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => reject(error));
        });
    },
};

const mutations = {
    SET_SUBCATEGROIES: (state, payload) => {
        state.subcategories = payload.map(({ products, ...rest }) => ({
            ...rest,
            products: products.map(({ id, title_en, title_ar }) => ({
                id,
                title_en,
                title_ar,
            })),
        }));
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
