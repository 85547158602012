import axios from "./axios-auth";

const state = {
    users: [],
    notifications: [],
};

const getters = {
    all(state) {
        return state.users;
    },
    notifications(state) {
        return state.notifications;
    },
};

const actions = {
    getStaff({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            axios.get("/api/staff").then((response) => {
                commit("SET_USERS", response.data);
                resolve(response);
            });
        });
    },
    getUsers({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            axios.get("/api/users").then((response) => {
                commit("SET_USERS", response.data.users);
                resolve(response);
            });
        });
    },
    fetchUser({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.get(`/api/users/${payload}`).then((response) => {
                resolve(response);
            });
        });
    },
    fetchUserFavourites({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.get(`/api/list-favourites`).then((response) => {
                resolve(response);
            });
        });
    },
    fetchUserNotifications({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.get(`/api/notifications`).then((response) => {
                commit("SET_NOTIFICATIONS", response.data);
                resolve(response);
            });
        });
    },
    markAsRead({}, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post(`/api/users/mark/${payload}/as-read`)
                .then((response) => {
                    resolve(response);
                });
        });
    },
    updateUser({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.put("/api/users/" + payload.id, payload).then((response) => {
                dispatch("auth/refreshUserData", {}, { root: true });
                resolve(response);
            });
        });
    },
    removeUser({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.delete("/api/users/" + payload).then((response) => {
                commit("REFRESH_USERS");
                resolve(response);
            });
        });
    },
    findUserByPhone({}, payload) {
        // /user-exist/
        return new Promise((resolve, reject) => {
            axios
                .get(`/api/user-exist/${payload}`, { validateStatus: false })
                .then(
                    (response) => {
                        resolve(response);
                    },
                    (error) => {
                        reject(error);
                    }
                );
        });
    },
    doesUserExist({}, payload) {
        // /user-exist/
        return new Promise((resolve, reject) => {
            axios.get(`/api/user/${payload}/exists`).then(
                (response) => {
                    resolve(response);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    },
    favouriteProduct({}, payload) {
        return new Promise((resolve, reject) => {
            axios
                .put("/api/users/add-favourite/" + payload)
                .then((response) => {
                    resolve(response);
                });
        });
    },
    createUserNote({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.post("/api/users/notes", payload).then((response) => {
                resolve(response);
            });
        });
    },
    removeUser({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.delete("/api/users/" + payload).then((response) => {
                resolve(response);
            });
        });
    },
    removeUserNote({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.delete("/api/users/notes/" + payload).then((response) => {
                resolve(response);
            });
        });
    },
    savePhoneNumber({}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`/api/save-phone-number`, payload).then(
                (response) => {
                    resolve(response);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    },
    removePhoneNumber({}, payload) {
        return new Promise((resolve, reject) => {
            axios
                .delete(
                    `/api/remove/${payload.user_id}/phone-number/${payload.id}`
                )
                .then(
                    (response) => {
                        resolve(response);
                    },
                    (error) => {
                        reject(error);
                    }
                );
        });
    },
};

const mutations = {
    SET_USERS: (state, users) => (state.users = users),
    REFRESH_USERS: (state, user_id) => {
        const index = state.users.findIndex((user) => user.id == user_id);
        state.users.splice(index, 1);
    },
    SET_NOTIFICATIONS: (state, notifications) => {
        state.notifications = notifications;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
