import axios from "./axios-auth";
import noauth from "axios";

const state = {
    products: [],
    sides: [],
    favorites: [],
    variants: [],
    variant_values: [],
};

const getters = {
    all(state) {
        return state.products;
    },
    sides(state) {
        return state.sides;
    },
    favorites(state) {
        return state.favorites;
    },
    variants(state) {
        return state.variants;
    },
    variant_values(state) {
        return state.variant_values;
    },
};

const actions = {
    favoriteProduct({}, payload) {
        return new Promise((resolve, reject) => {
            axios
                .put(`/api/users/add-favourite/${payload}`)
                .then((response) => {
                    resolve(response);
                });
        });
    },
    massUploadProducts({}, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/api/products/mass-upload", payload)
                .then((response) => {
                    resolve(response);
                });
        });
    },
    getProducts({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            axios.get("/api/products").then((response) => {
                commit("SET_PRODUCTS", response.data.products);
                resolve(response);
            });
        });
    },
    getProductsByCategory({}, payload) {
        return new Promise((resolve, reject) => {
            axios.get("/api/products/category/" + payload).then((response) => {
                // commit("SET_PRODUCTS", response.data.products);
                resolve(response);
            });
        });
    },
    fetchVariants({ commit }) {
        return new Promise((resolve, reject) => {
            axios.get("/api/variants").then((response) => {
                if (response.data.statusCode == 200) {
                    commit("SET_VARIANTS", response.data.variants);
                }

                resolve(response);
            });
        });
    },
    fetchVariantValues({ commit }) {
        return new Promise((resolve, reject) => {
            axios.get("/api/variants/values").then((response) => {
                if (response.data.statusCode == 200) {
                    commit("SET_VARIANT_VALUES", response.data.values);
                }

                resolve(response);
            });
        });
    },
    getProduct({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.get("/api/products/" + payload).then((response) => {
                resolve(response);
            });
        });
    },
    // createVariant({}, payload) {
    //     return new Promise((resolve, reject) => {
    //         axios
    //             .post(`/api/products/${payload.id}/variants`, payload.body)
    //             .then((response) => {
    //                 resolve(response);
    //             })
    //             .catch((error) => {
    //                 reject(error);
    //             });
    //     });
    // },
    // removeVariant({}, payload) {
    //     return new Promise((resolve, reject) => {
    //         axios
    //             .delete(`/api/products/${payload}/variants`)
    //             .then((response) => {
    //                 resolve(response);
    //             })
    //             .catch((error) => {
    //                 reject(error);
    //             });
    //     });
    // },
    // updateVariant({ dispatch }, payload) {
    //     return new Promise((resolve, reject) => {
    //         axios
    //             .put(`/api/products/${payload.id}/options`, payload)
    //             .then((response) => {
    //                 resolve(response);
    //             });
    //     });
    // },
    searchProducts({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.get("/api/search/products" + payload).then((response) => {
                resolve(response);
            });
        });
    },
    createProduct({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.post("/api/products", payload).then((response) => {
                resolve(response);
            });
        });
    },
    updateProduct({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .put("/api/products/" + payload.id, payload)
                .then((response) => {
                    resolve(response);
                });
        });
    },
    saveProductImages({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/api/products/save-images", payload)
                .then((response) => {
                    resolve(response);
                });
        });
    },
    setOptionImageAsMain({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/api/products/set-option-image-as-main", payload)
                .then((response) => {
                    resolve(response);
                });
        });
    },
    removeProduct({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.delete("/api/products/" + payload).then((response) => {
                commit("REFRESH_PRODUCTS");
                resolve(response);
            });
        });
    },
    hideDraft({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post(`/api/draft-products/${payload.draft_id}/hide`, payload)
                .then((response) => {
                    resolve(response);
                });
        });
    },
    hideBulk({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post(`/api/draft-products/hide-bulk`, payload)
                .then((response) => {
                    resolve(response);
                });
        });
    },
};

const mutations = {
    SET_VARIANTS: (state, variants) => (state.variants = variants),
    SET_VARIANT_VALUES: (state, values) => (state.variant_values = values),
    SET_PRODUCTS: (state, products) => (state.products = products),
    REFRESH_PRODUCTS: (state, product_id) => {
        const index = state.products.findIndex(
            (product) => product.id == product_id
        );
        state.products.splice(index, 1);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
