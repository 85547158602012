import axios from "axios";
import store from "../store";

const instance = axios.create();

instance.defaults.headers.common[
    "Authorization"
] = `Bearer ${localStorage.getItem("token")}`;

instance.interceptors.request.use(config => {
    // Do something before request is sent
    return config;
});

instance.interceptors.response.use(
    res => {
        // console.log(res);

        return res;
    },
    err => {
        if (err.response.status == 401) {
            // send a login request

            // console.log(this.$store.state.auth.data);
            if (store.state.auth.user != null) {
                store
                    .dispatch("auth/refreshToken", {
                        phone: store.state.auth.data.phone,
                        password: store.state.auth.data.password
                    })
                    .then(() => {
                        console.log("Refreshed!");
                        // err.config.headers[
                        //     "Authorization"
                        // ] = `Bearer ${localStorage.getItem("token")}`;
                        // error.config.baseURL = undefined;
                        // return instance.request(err.config);
                        window.location.reload();
                    });
            }

            // localStorage.removeItem("token");
            // localStorage.removeItem("user");
            // window.location.replace("/login");
        }
    }
);

export default instance;
